import React from 'react'
import styled from 'styled-components'

import { RiTreasureMapFill, BsFillCalendarEventFill, MdArticle } from '../../../utils/GetIcons'
import { POST_TYPE_CONSTANTS } from '../../../constants'
import CardContainer from '../../common/CardContainer'
import CardDescription from '../../common/text/CardDescription'

const Card = styled(CardContainer)`
  background-color: ${props => props.theme.greyscale100secondary800};
  display: flex;
  flex-direction: row;
  height: 8rem;
  text-decoration: none;
  transition: background-color 300ms ease-in-out;
  width: calc(100vw - 3rem);
  @media (min-width: 480px) {
    transition:
      background-color 300ms ease-in-out,
      transform 300ms ease-in-out,
      box-shadow 300ms ease-in-out;
    &:hover {
      box-shadow: var(--shadow-lifted);
      transform: translateY(-0.5rem);
    }
  }
  @media (min-width: 768px) {
    height: var(--dimension-card-0);
    flex-direction: column;
    width: 13rem;
  }
`

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.primary700to300};
  height: 100%;
  justify-content: center;
  width: 38%;
  @media (min-width: 480px) {
    transition: transform 300ms ease-in-out;
    ${Card}:hover & {
      transform: scale(1.05);
    }
  }
  @media (min-width: 768px) {
    height: 38%;
    width: 100%;
  }
`

const TextContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto 0 auto auto;
  padding: 0.5rem 1rem;
  padding-left: 0;
  position: relative;
  transition: background-color 300ms ease-in-out;
  width: 62%;
  @media (min-width: 768px) {
    height: 62%;
    justify-content: start;
    margin: auto;
    padding: 0 1.5rem 1rem 1.5rem;
    text-align: center;
    width: 100%;
  }
`

const CardTitle = styled.h1`
  color: ${props => props.theme.black200greyscale100};
  font: 700 var(--fontSize-3) / 1.2 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-2);
  margin: 0;
  text-align: start;
  @media (min-width: 480px) {
    font-size: var(--fontSize-4);
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    line-height: 1;
    margin: 0 auto 0.25rem auto;
    text-align: center;
  }
`

const Description = styled(CardDescription)`
  -webkit-line-clamp: 5;
`

interface Props {
  contentType: any
}

export default function ContentCard({ contentType }: Props): JSX.Element {
  let link = contentType.toLowerCase()
  if (contentType === 'articles') link = 'posts'
  const textConstants = POST_TYPE_CONSTANTS[contentType.toLowerCase() as 'events' | 'articles' | 'routes'] ?? {}

  const getIcon = (): JSX.Element => {
    switch (link) {
      case 'routes':
        return <RiTreasureMapFill size={46} />
      case 'events':
        return <BsFillCalendarEventFill size={40} />
      default:
        return <MdArticle size={51} />
    }
  }

  return (
    <Card to={`/${link}`} role="button">
      <IconContainer>{getIcon()}</IconContainer>
      <TextContainer>
        <CardTitle>{textConstants.PAGE_TITLE}</CardTitle>
        <Description>{textConstants.DESCRIPTION_SHORT}</Description>
      </TextContainer>
    </Card>
  )
}
