import React, { lazy } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import useWindowSize from '../utils/hooks/useWindowSize'
import addSuspense from '../utils/AddSuspense'

import Logo from '../components/elements/Logo'
import Bio from '../components/elements/bio'
import Layout from '../components/layouts/layout'
import EventHighlight from '../components/sections/EventsWrapper'
import Seo from '../components/seo'
import WhatWeDo from '../components/sections/WhatWeDo'

const PopDest = lazy(async () => await import('../components/sections/PopDest'))
const CarouselSection = lazy(async () => await import('../components/sections/CarouselSection'))

const ImageContainer = styled.div`
  display: inline-block;
  height: calc(100dvh - 4rem);
  position: relative;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  @media (min-width: 480px) {
    height: 100vh;
  }
  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
`

const ImageOverlay = styled.div`
  align-items: center;
  background-color: transparent;
  box-shadow:
    inset 8px 0 8px -8px black,
    inset -8px 0 8px -8px black,
    inset 0 8px 8px -8px black,
    inset 0 -8px 8px -8px black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  @media (max-width: 479px) {
    padding-bottom: 4rem;
  }
  @media (min-width: 480px) {
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow:
      rgba(0, 0, 0, 0.8) 0rem 5.5rem 4rem -1rem inset,
      inset 8px 0 8px -8px black,
      inset -8px 0 8px -8px black,
      inset 0 8px 8px -8px black,
      inset 0 -8px 8px -8px black;
  }
`

const Description = styled.h2`
  display: none;
  @media (min-width: 480px) {
    color: var(--color-greyscale-300);
    display: inline-block;
    font: 400 var(--fontSize-2) / 1.2 var(--font-montserrat);
    letter-spacing: var(--letterSpacing-1);
    margin: 0;
    text-align: center;
    text-shadow:
      0px 4px 3px rgba(0, 0, 0, 0.8),
      0px 8px 13px rgba(0, 0, 0, 0.4),
      0px 18px 23px rgba(0, 0, 0, 0.4);
  }
`

interface Props {
  data: any
  location: any
}

export default function BlogIndex({ data, location }: Props): JSX.Element {
  const continents = data.allDataLocationsJson?.nodes
  const { nodes } = data.allMarkdownRemark
  const posts = nodes.filter((node: any) => node.frontmatter.type.toLowerCase() === 'article')
  const routes = nodes.filter((node: any) => node.frontmatter.type.toLowerCase() === 'route')
  const events = nodes.filter((node: any) => node.frontmatter.type.toLowerCase() === 'event')
  const size = useWindowSize()

  if (nodes.length === 0) {
    return (
      <Layout location={location}>
        <Bio />
        <p>Error. No blog posts found.</p>
      </Layout>
    )
  }

  const desktopHero = (
    <ImageContainer>
      <StaticImage
        className="headerimage"
        src="../images/heroes/coverlake.jpg"
        alt="Big old Lake"
        placeholder="blurred"
        loading="eager"
      />
      <ImageOverlay>
        <Logo variation="huge" />
        <Description>Find a reason to travel</Description>
      </ImageOverlay>
    </ImageContainer>
  )

  const mobileHero = (
    <ImageContainer>
      <StaticImage
        className="headerimage"
        src="../images/heroes/mobile-peak.jpg"
        alt="Big old Lake"
        placeholder="blurred"
        loading="eager"
      />
      <ImageOverlay>
        <Logo variation="huge" />
      </ImageOverlay>
    </ImageContainer>
  )

  return (
    <Layout location={location}>
      {size.width >= 480 && desktopHero}
      {size.width < 480 && mobileHero}
      <WhatWeDo />
      <EventHighlight posts={events} />
      {addSuspense(<PopDest continents={continents} />)}
      {addSuspense(<CarouselSection type={'articles'} posts={posts} />)}
      {addSuspense(<CarouselSection type={'routes'} posts={routes} />)}
    </Layout>
  )
}

interface WebSite {
  '@context': string
  '@type': string
  name: string
  url: string
}

/* Head export to define metadata https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/ */
export function Head({ location, data: { site } }: any): JSX.Element {
  const structuredDataWebSite: WebSite = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: site.siteMetadata?.title,
    url: `${site?.siteMetadata?.siteUrl}/`,
  }

  return (
    <Seo title="Home" location={location} description={site.siteMetadata?.description} schema={structuredDataWebSite} />
  )
}

export const pageQuery = graphql`
  query indexQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allDataLocationsJson {
      nodes {
        title
        description
        countries {
          name
          continent
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 600, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { frontmatter: { date: DESC } }) {
      nodes {
        excerpt(pruneLength: 140)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          startdate(formatString: "MMMM DD, YYYY")
          enddate(formatString: "MMMM DD, YYYY")
          title
          aka
          description
          type
          subtype
          duration
          city
          country
          countries
          continent
          hero_image_credit_text
          hero_image_credit_link
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 700, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
