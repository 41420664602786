import React from 'react'
import styled from 'styled-components'

import { RiTreasureMapFill, BsFillCalendarEventFill, MdArticle } from '../../../utils/GetIcons'
import { POST_TYPE_CONSTANTS } from '../../../constants'
import CardContainer from '../../common/CardContainer'

const Card = styled(CardContainer)`
  background-color: ${props => props.theme.greyscale100secondary800};
  border-radius: var(--border-radius-6);
  box-shadow: var(--shadow-tight);
  flex-direction: column;
  height: 6rem;
  text-decoration: none;
  transition:
    background-color 300ms ease-in-out,
    transform 100ms ease-in-out;
  width: 27vw;
  will-change: transform;
  -webkit-tap-highlight-color: transparent;
  &:active {
    transform: scale(0.95);
  }
`

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.primary700to300};
  height: 100%;
  justify-content: center;
  padding-top: 0.5rem;
  width: 100%;
`

const CardTitle = styled.h1`
  align-items: center;
  color: ${props => props.theme.black200greyscale100};
  display: flex;
  font: 700 var(--fontSize-2) / 1.5 var(--font-montserrat);
  justify-content: center;
  height: 100%;
  letter-spacing: var(--letterSpacing-2);
  margin: auto 0;
  padding: 0 1rem 0.5rem 1rem;
  text-align: start;
  transition: background-color 300ms ease-in-out;
  width: 100%;
`

interface Props {
  contentType: any
}

export default function MiniContentCard({ contentType }: Props): JSX.Element {
  const textConstants = POST_TYPE_CONSTANTS[contentType.toLowerCase() as 'events' | 'articles' | 'routes'] ?? {}
  const link = contentType.toLowerCase()

  const getIcon = (): JSX.Element => {
    switch (link) {
      case 'routes':
        return <RiTreasureMapFill size={30} />
      case 'events':
        return <BsFillCalendarEventFill size={25} />
      default:
        return <MdArticle size={32} />
    }
  }

  return (
    <Card to={`/${link}`}>
      <IconContainer>{getIcon()}</IconContainer>
      <CardTitle>{textConstants.PAGE_TITLE}</CardTitle>
    </Card>
  )
}
