import React from 'react'
import styled from 'styled-components'
import SectionHeader from './headers/SectionHeader'
import ContentCard from '../elements/cards/ContentCard'
import MiniContentCard from '../elements/cards/ContentCardMini'
import useWindowSize from '../../utils/hooks/useWindowSize'
import Reveal from '../../utils/Reveal'

const Section = styled.section`
  margin: 1rem auto auto auto;
  overflow: visible;
  width: 100%;
`

const CardList = styled.div<{ $useColumns: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  list-style: none;
  margin: auto;
  padding: 0 1.5rem 2rem 1.5rem;
  width: 100%;
  @media (max-width: 767px) {
    ${({ $useColumns }) => $useColumns && `flex-direction: column;`}
  }
  @media (min-width: 768px) {
    gap: 1.5rem;
    padding: 0 2rem 2rem 2rem;
    width: min-content;
  }
`

interface Props {
  useBigCards?: boolean
}

export default function WhatWeDo({ useBigCards = false }: Props): JSX.Element {
  const contentTypes = ['Events', 'Articles', 'Routes']
  const size = useWindowSize()

  const renderContentCard = (contentType: string): JSX.Element => {
    return size.width < 768 && !useBigCards ? (
      <MiniContentCard key={contentType} contentType={contentType} />
    ) : (
      <ContentCard key={contentType} contentType={contentType} />
    )
  }

  return (
    <Section id="exploreSection">
      <SectionHeader title={'Explore'} subtitle={'Find a reason to travel'} />
      <CardList $useColumns={useBigCards}>
        {contentTypes.map((contentType: any, index: number) => (
          <Reveal key={index} effect="fade-up" delay={index}>
            {renderContentCard(contentType)}
          </Reveal>
        ))}
      </CardList>
    </Section>
  )
}
