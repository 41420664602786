import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import { MONTHS_LONG } from '../../constants'
import NavArrow from '../elements/buttons/NavArrow'
import CarouselSection from './CarouselSection'
import Reveal from '../../utils/Reveal'

const MonthSelector = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto auto 0.5rem auto;
  min-width: 15rem;
  max-width: 20rem;
  @media (min-width: 768px) {
    margin: 1rem auto 2rem auto;
  }
`

const MonthTitle = styled.h1`
  color: ${props => props.theme.black400greyscale300};
  font: 500 var(--fontSize-3) / 1 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-1);
  margin: auto;
  text-align: center;
  @media (min-width: 980px) {
    font-size: var(--fontSize-4);
  }
`

interface Props {
  posts: any
}

const EventsWrapper = React.memo(function ({ posts }: Props): JSX.Element {
  EventsWrapper.displayName = 'EventsWrapper'
  const monthsRef = useRef(null)
  const realtimeMonth = new Date().getMonth()
  const [index, setIndex] = useState(realtimeMonth)
  const [currentMonth, setCurrentMonth] = useState(`${MONTHS_LONG[realtimeMonth]}`)

  const postList = posts
    .filter(
      (post: any) =>
        new Date(post.frontmatter.startdate).toLocaleDateString('en-US', { month: 'long' }) === currentMonth ||
        new Date(post.frontmatter.enddate).toLocaleDateString('en-US', { month: 'long' }) === currentMonth
    )
    .sort((a: any, b: any) => {
      const dateA = new Date(a.frontmatter.startdate).getTime()
      const dateB = new Date(b.frontmatter.startdate).getTime()
      return dateA - dateB
    })
    .slice(0, 4)

  const changeMonth = (direction: 'increment' | 'decrement'): void => {
    if (direction === 'increment') {
      const newIndex = index === 11 ? 0 : index + 1
      setCurrentMonth(`${MONTHS_LONG[newIndex]}`)
      setIndex(newIndex)
    } else {
      const newIndex = index === 0 ? 11 : index - 1
      setCurrentMonth(`${MONTHS_LONG[newIndex]}`)
      setIndex(newIndex)
    }
  }

  const decrementMonth = (): void => {
    changeMonth('decrement')
  }

  const incrementMonth = (): void => {
    changeMonth('increment')
  }

  return (
    <CarouselSection type={'events'} posts={postList}>
      <Reveal effect="fade-in">
        <MonthSelector ref={monthsRef}>
          <NavArrow direction={'left'} clickFunction={decrementMonth} />
          <MonthTitle>{currentMonth}</MonthTitle>
          <NavArrow direction={'right'} clickFunction={incrementMonth} />
        </MonthSelector>
      </Reveal>
    </CarouselSection>
  )
})

export default EventsWrapper
